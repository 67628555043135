import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import { DashedBorder, Layout, Menu } from '../components';

const AboutPage = () => {
  return (
    <Layout ogTitle="About" ogImage="/tessa.jpg">
      <Container maxWidth={false} disableGutters>
        <Container maxWidth="lg">
          <Menu />
        </Container>
        <Container maxWidth="md">
          <Box
            mt={8}
            mb={2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid container mb={8}>
              <Grid item xs={2} md={4} />
              <Grid item xs={8} md={4}>
                <Card raised>
                  <CardMedia>
                    <StaticImage src="../images/tessa.jpg" alt="Tessa" />
                  </CardMedia>
                </Card>
              </Grid>
              <Grid item xs={2} md={4} />
            </Grid>
            <Typography variant="h1">Hi! I am Tessa</Typography>
            <br />
            <Typography variant="h5" paragraph>
              A Dutch crochet lover who likes to spend her free time mostly with
              crocheting, trying out new crochet stitches and now for Tiny
              Tessa’s making her own crochet patterns.
            </Typography>
            <DashedBorder />
            <Box mt={2} />
            <Typography paragraph>
              In my day to day life I work as a test engineer at Quooker;
              conducting all kind of tests to improve the system of the hot
              boiling water, chilled and cooled water tap. Something completely
              different than designing crochet patterns. But the iteration
              stages are the same, thinking of a goal and building (crocheting)
              the prototype. When I am not at work you'll see me mostly with a
              crochet needle in my hand. For example while commuting to and from
              work in the train. Or at home you can find little bits of yarn and
              crochet needles everywhere.
            </Typography>
            <Typography paragraph>
              My creativity and interest in crochet is all coming from my
              grandma Tiny, (the Tiny of Tiny Tessa’s). During one of my
              projects in my student time I had to develop a product line where
              we would combine traditional and modern handicrafts. I had the
              idea to reproduce crocheted doilies with 3D printing.
              Unfortunately I could not crochet very thight and make beautiful
              doilies. However, my grandma could, so she crocheted them for me
              and I made replications of them with 3D scanning and 3D printing.
              After this project I wanted to learn how to crochet myself. It
              resulted in watching lots of YouTube videos, trying tutorials and
              failed projects. But over the years (almost seven now) my crochet
              skills have advanced and everyday I still learn new tricks.
            </Typography>
            <Typography paragraph>
              I like to crochet amigurumi the most. Making little animals and
              cuddly toys that are great as a gift for family and friends. Once
              in a while I also crochet stuff for the house, but these projects
              are bigger and take more time to crochet. Working on the somewhat
              smaller crochet projects I enjoy the most. This way I can work on
              many different ideas and create lots of different rattles, cuddly
              toys and other fun stuff.
            </Typography>
          </Box>
        </Container>
      </Container>
    </Layout>
  );
};

export default AboutPage;
